import {
  createRoot
} from 'react-dom/client';

import './index.less';

import {
  getRootDom
} from '@/util';
import App from '@/app';

createRoot(getRootDom()).render(<App />);
