import {
  ReactElement
} from 'react';
// import {
//   Route
// } from 'react-router-dom';

import ModelRoot from '@/model/model-root';
import AppNavTop from '@/rc-container/app-nav-top';

import SiteApp from './rc/site-app';

export default function RootRoute(): ReactElement {
  return <ModelRoot>
    <AppNavTop />
    <SiteApp />
  </ModelRoot>;
}