import {
  useCallback
} from 'react';
import useDispatchLoggedChange from './use-dispatch-logged-change.ts';

export default function useHandleMessageLoggedChange(): (e: MessageEvent) => void {
  const dispatchLoggedChange = useDispatchLoggedChange();
  
  return useCallback((e: MessageEvent) => {
    if (!e.data || typeof e.data !== 'object' || !(e.data.type === 'lcai.console.login' || e.data.type === 'lcai.console.logout')) {
      return;
    }
    
    dispatchLoggedChange();
  }, [dispatchLoggedChange]);
}
