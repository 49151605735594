export default function getRootDom(): HTMLElement {
  const rootId = 'root';
  let root = document.getElementById(rootId);
  
  if (!root) {
    root = document.createElement('div');
    root.id = rootId;
    document.body.appendChild(root);
  }
  
  return root;
}