import {
  ReactElement
} from 'react';
import styled from 'styled-components';

const ScHomeIcon = styled.span`
  display: block;
  font-size: 2em;
`;
const ScHome = styled.h1`
  margin: 4em;
  font-size: 5em;
  text-align: center;
  color: #535bf2;
`;

export default function Home(): ReactElement {
  return <ScHome><ScHomeIcon role="img">⛺</ScHomeIcon>This is HOME!️</ScHome>;
}