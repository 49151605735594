import auth, {
  checkLogged
} from '@lcai/console-auth';

import {
  IModelProps,
  IModelState
} from '../types';

export default function createInitialState(_props: IModelProps): IModelState {
  return {
    asideCollapsed: false,
    account: auth(),
    accountLogged: checkLogged()
  };
}