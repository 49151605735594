import {
  ReactElement
} from 'react';
import styled from 'styled-components';
import {
  useLocation
} from 'react-router-dom';

const ScErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  h1 {
    margin: 0;
    font-size: 6em;
  }
`;

export default function Error404(): ReactElement {
  const {
    pathname
  } = useLocation();
  
  return <ScErrorPage>
    <h1>404</h1>
    <p>Cannot find path <code>{pathname}</code>.</p>
  </ScErrorPage>;
}