import { ReactElement, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePath } from "@/helper/helper-route";
import { Menu } from "antd";
import { CalculatorOutlined, DatabaseOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
type MenuItem = Required<MenuProps>["items"][number];

const menuItems: MenuItem[] = [
    {
        key: RoutePath.TABLE_STRUCTURE,
        label: "自定义字典",
        icon: <DatabaseOutlined />
    },
    {
        key: `${RoutePath.CALCULATE}/flow`,
        label: "工程计算",
        icon: <CalculatorOutlined />,
        // children: [
        //     {
        //         key: `${RoutePath.CALCULATE}/flow`,
        //         label: "计算公式"
        //     },
        //     {
        //         key: `${RoutePath.CALCULATE}/form`,
        //         label: "参数表单"
        //     }
        // ]
    }
];

const getBasePathAndOpenKey = (pathname: String) => {
    const basePaths = [
        { key: RoutePath.TABLE_STRUCTURE, parent: null },
        { key: `${RoutePath.CALCULATE}/flow`, parent: null },
        // { key: `${RoutePath.CALCULATE}/form`, parent: RoutePath.CALCULATE }
    ];

    const match = basePaths.find(({ key }) => pathname.startsWith(key)) || { key: "/home", parent: null };
    return { selectedKey: match.key, openKey: match.parent };
};

export default function AppNavAside(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState("");
    const [openKey, setOpenKey] = useState("");

    const handleNavItemClick: MenuProps["onClick"] = e => {
        navigate(e.key);
    };

    useEffect(() => {
        if (location.pathname && location.pathname !== "/") {
            const match = getBasePathAndOpenKey(location.pathname);
            setSelectedKey(match.selectedKey);
            setOpenKey(match.openKey);
        } else {
            setSelectedKey(""); // Clear selection if no match is found
            setOpenKey("");
        }
    }, [location.pathname]);

    return (
        <Menu
            selectedKeys={[selectedKey]}
            openKeys={openKey ? [openKey] : []}
            onClick={handleNavItemClick}
            onOpenChange={keys => setOpenKey(keys.pop() || "")}
            style={{ width: 256, paddingTop: 12 }}
            mode='inline'
            items={menuItems}
        />
    );
}
