import { ReactElement } from "react";
import styled from "styled-components";

import AppNavAside from "@/rc-container/app-nav-aside";

import SiteMain from "../site-main";

const ScSiteApp = styled.div`
    display: flex;
    height: 100%;
`;

export default function SiteApp(): ReactElement {
    return (
        <ScSiteApp>
            <AppNavAside />
            <SiteMain />
        </ScSiteApp>
    );
}
