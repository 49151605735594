import {
  produce
} from 'immer';

import auth, {
  checkLogged
} from '@lcai/console-auth';

import {
  IModelState
} from '../types';

export default function reduceLoggedChange(state: IModelState): IModelState {
  return produce(state, draft => {
    draft.account = auth();
    draft.accountLogged = checkLogged();
  });
}
