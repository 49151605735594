import {
  IModelState,
  TModelAction
} from '../types';
import {
  EAction
} from '../enum';

import reduceToggleAsideCollapsed from './reduce-toggle-aside-collapsed';
import reduceLoggedChange from './reduce-logged-change.ts';

export default function reducer(state: IModelState, action: TModelAction): IModelState {
  switch (action.type) {
    case EAction.TOGGLE_ASIDE_COLLAPSED:
      return reduceToggleAsideCollapsed(state);
    case EAction.LOGGED_CHANGE:
      return reduceLoggedChange(state);
    default:
      return state;
  }
}
