import {
  useEffect
} from 'react';

import useHandleMessageLoggedChange from './use-handle-message-logged-change.ts';

export default function useEffectLoggedChange(): void {
  const handleMessageLoggedIn = useHandleMessageLoggedChange();
  
  useEffect(() => {
    window.addEventListener('message', handleMessageLoggedIn);
    
    return () => window.removeEventListener('message', handleMessageLoggedIn);
  }, [handleMessageLoggedIn]);
}
