import {
  ReactElement
} from 'react';
import {
  Outlet,
  useLocation
} from 'react-router-dom';
import styled from 'styled-components';

import ErrorBoundary from '@lcai/console-rc-error-boundary';

const ScSiteMain = styled.main`
  flex: 1;
  order: 1;
  height: 100%;
  overflow-y: auto;
`;

export default function SiteMain(): ReactElement {
  const {
    pathname
  } = useLocation();

  // 给 ErrorBoundary 加上 key 可以防止路由变化后一直处于错误状态的问题
  return <ScSiteMain>
    <ErrorBoundary key={pathname}>
      <Outlet />
    </ErrorBoundary>
  </ScSiteMain>;
}
