import {
  lazy,
  ReactElement,
  Suspense
} from 'react';
import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';

import {
  RoutePath
} from '@/helper/helper-route';
import Error404 from '@/rc-container/error-404';
import RouteRoot from '@/route/_root';
import RouteHome from '@/route/home';

const PageTableStructure = lazy(() => import('@lcai/console-page-table-structure'));
const PageCalculate = lazy(() => import('@lcai/console-page-calculate'));

const router = createBrowserRouter([
  {
    path: RoutePath.ROOT,
    element: <RouteRoot />,
    // errorElement: <ErrorPage />,
    // loader: rootLoader,
    // action: rootAction,
    children: [
      {
        index: true,
        element: <RouteHome />
      },
      {
        path: RoutePath.TABLE_STRUCTURE + '/*',
        element: <Suspense><PageTableStructure pathPrefix={RoutePath.TABLE_STRUCTURE} /></Suspense>
      },
      {
        path: RoutePath.CALCULATE + '/*',
        element: <Suspense><PageCalculate pathPrefix={RoutePath.CALCULATE} /></Suspense>
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
]);

export default function App(): ReactElement {
  return <RouterProvider router={router} />;
}
