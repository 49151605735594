import { ReactElement, useEffect, useState } from "react";
import { Avatar, Space, Flex, Modal, Form, Input, Button } from "antd";
import styled from "styled-components";
import { dataAuthLogin, dataAuthLogout } from "../../../packages-data/console-data-auth";
import { useAccount } from "../../model/model-root";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 4px;

    .account {
        border-left: 1px solid #ebebeb;
        padding: 0 14px;
        cursor: pointer;
    }

    .account_status {
        font-size: 12px;
        color: #999;
    }
`;

const Logo = styled.a`
    padding: 0 14px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #ff6a00;
    text-decoration: unset;

    &:hover {
        background-color: #f4f6f7;
    }
`;

const LoginTitle = styled.h2`
    text-align: center;
    margin: 30px 0;
`;

export default function AppNavTop(): ReactElement {
    const account = useAccount();
    const [accountLogged, setAccountLogged] = useState(localStorage.getItem("token") ? true : false);

    const handleAccountLogin = () => {
        const modal = Modal.confirm({
            title: false,
            icon: null,
            content: (
                <>
                    <LoginTitle>登 录</LoginTitle>
                    <Form
                        layout='vertical'
                        onFinish={values => {
                            dataAuthLogin(values).then(res => {
                                if (res && res.token) {
                                    localStorage.setItem("token", res.token);
                                    modal.destroy();
                                    setAccountLogged(true);
                                }
                            });
                        }}
                        style={{ marginBottom: 30 }}>
                        <Form.Item
                            name='username'
                            label='用户名'
                            rules={[
                                {
                                    required: true,
                                    message: "请输入用户名"
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label='密码'
                            rules={[
                                {
                                    required: true,
                                    message: "请输入密码"
                                }
                            ]}>
                            <Input.Password placeholder='请输入密码' />
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' style={{ width: "100%", marginTop: 30 }}>
                                登 录
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            ),
            footer: false
        });
    };

    const handleAccountLoginOut = () => {
        Modal.confirm({
            title: "登出账号",
            content: "您确定退出登录当前账号？",
            cancelText: "再想想",
            okText: "坚持退出",
            onOk: () => {
                dataAuthLogout().then(() => {
                    localStorage.setItem("token", "");
                    setAccountLogged(false);
                });
            }
        });
    };

    useEffect(() => {
        if (!accountLogged) handleAccountLogin();
    }, [accountLogged]);

    return (
        <>
            <Header>
                <Logo href='/'>良策控制台</Logo>
                <Space className='account' onClick={accountLogged ? handleAccountLoginOut : handleAccountLogin}>
                    <Flex vertical>
                        <span className='account_name'>{account.name || "未登录"}</span>
                        <span className='account_status'>{accountLogged ? "点击退出" : "点击登录"}</span>
                    </Flex>
                    <Avatar
                        size={36}
                        src='//img.alicdn.com/imgextra/i1/19999999999999/O1CN010pFXIi2NjasoACbpZ_!!19999999999999-2-tps.png'
                    />
                </Space>
            </Header>
        </>
    );
}
